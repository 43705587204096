import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
} from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { BoardCardService } from 'src/app/settings-app/boards/card/board-card.service';
import { MassOperationHelper } from 'src/app/shared/helpers/mass-operation.helper';
import { BoardTeamMember } from 'src/app/settings-app/boards/model/board.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tmt-board-card-permissions',
  templateUrl: './permissions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardCardPermissionsComponent {
  @Input() entityId: string;
  @Input() readonly: boolean;
  @Input() permissions: FormArray;
  @Input() team: BoardTeamMember[];
  @Input() isSaving$: BehaviorSubject<boolean>;

  private destroyRef = inject(DestroyRef);

  constructor(
    public boardCardService: BoardCardService,
    private fb: FormBuilder,
    private actionPanelService: ActionPanelService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private translateService: TranslateService,
  ) {}

  /** Saves board team. */
  public saveBoardTeam(): void {
    const team: {
      insert: Partial<BoardTeamMember>[];
      update: Partial<BoardTeamMember>[];
      delete: string[];
    } = this.getSavingArrays();

    if (!team.insert.length && !team.delete.length && !team.update.length) {
      this.isSaving$.next(false);
      this.actionPanelService.action('save').stop();
      this.notificationService.successLocal(
        'components.boardCardPermissionsComponent.messages.saved',
      );
      return;
    }

    const massOperationHelper = new MassOperationHelper(
      team.delete
        .map((id: string) =>
          this.dataService.collection('BoardTeamMembers').entity(id).delete(),
        )
        .concat(
          team.insert.map((item: Partial<BoardTeamMember>) =>
            this.dataService.collection('BoardTeamMembers').insert(item),
          ),
        )
        .concat(
          team.update.map((item: Partial<BoardTeamMember>) =>
            this.dataService
              .collection('BoardTeamMembers')
              .entity(item.id)
              .update(item),
          ),
        ),
      { takeUntil: takeUntilDestroyed(this.destroyRef) },
    );
    massOperationHelper
      .start()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((progress) => {
        if (progress === 100) {
          if (massOperationHelper.errors.length) {
            const uniqueErrors = new Set();
            massOperationHelper.errors.forEach((error) => {
              uniqueErrors.add(
                error.result?.message || 'shared2.messages.unknownError',
              );
            });
            uniqueErrors.forEach((error: string) => {
              this.notificationService.error(error);
            });
          } else if (
            massOperationHelper.completedCount ===
            team.insert.length + team.delete.length + team.update.length
          ) {
            this.notificationService.successLocal(
              'components.boardCardPermissionsComponent.messages.saved',
            );
            Object.keys(team).forEach((item) => (team[item] = []));
          }

          this.isSaving$.next(false);
          this.actionPanelService.action('save').stop();
        }
      });
  }

  /**
   * Gets arrays for saving.
   *
   * @returns object with array for insert, update and delete team members.
   */
  public getSavingArrays(): {
    insert: Partial<BoardTeamMember>[];
    update: Partial<BoardTeamMember>[];
    delete: string[];
  } {
    const team = {
      insert: [],
      update: [],
      delete: [],
    };
    for (const item of this.permissions.value) {
      const foundTeamMember = this.team.find(
        (teamMember) => item.id === teamMember.id,
      );
      if (foundTeamMember) {
        if (item.performer.id !== this.getMemberId(foundTeamMember)) {
          team.update.push({
            id: item.id,
            ...this.setItemPermissionId(
              item.performer.id,
              item.performer.type,
              'update',
            ),
          });
        }
      } else {
        team.insert.push({
          id: item.id,
          ...this.setItemPermissionId(
            item.performer.id,
            item.performer.type,
            'insert',
          ),
        });
      }
    }

    for (const item of this.team) {
      if (!this.permissions.value.find((teamItem) => teamItem.id === item.id)) {
        team.delete.push(item.id);
      }
    }

    return team;
  }

  /**
   * Sets team form array.
   *
   * @param team board team members array.
   */
  public setFormTeam(team: BoardTeamMember[]): void {
    this.permissions.clear();
    team.map((item) =>
      this.permissions.push(
        this.fb.group({
          id: item.id,
          performer: {
            id: this.getMemberId(item),
            type: item.userId
              ? 'user'
              : item.groupId
                ? 'group'
                : item.permissionSetId
                  ? 'permissionSet'
                  : 'role',
            name:
              item.user?.name ??
              item.group?.name ??
              item.permissionSet?.name ??
              this.translateService.instant(`shared2.props.role${item.role}`),
          },
        }),
      ),
    );
  }

  /**
   * Sets id property according to permission type.
   *
   * @param id setting id.
   * @param item board team member item.
   * @param type permission type.
   */
  private setItemPermissionId(
    id: string,
    type: string,
    methodName: string,
  ): Partial<BoardTeamMember> {
    let teamMember: Partial<BoardTeamMember>;
    switch (methodName) {
      case 'insert':
        teamMember = {
          isActive: true,
          boardId: this.entityId,
        };
        break;
      case 'update':
        teamMember = {
          isActive: true,
          boardId: this.entityId,
          groupId: null,
          userId: null,
          permissionSetId: null,
          role: '',
        };
        break;
    }

    switch (type) {
      case 'group':
        teamMember.groupId = id;
        break;
      case 'user':
        teamMember.userId = id;
        break;
      case 'permissionSet':
        teamMember.permissionSetId = id;
        break;
      case 'role':
        teamMember.role = id;
    }

    return teamMember;
  }

  /**
   * Gets team member id.
   * @param member member entity.
   * @returns string with id.
   */
  private getMemberId(member: BoardTeamMember): string {
    return (
      member.userId || member.groupId || member.permissionSetId || member.role
    );
  }
}
