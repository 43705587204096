<div class="row mt-4">
  <div class="col-12">
    <label class="group mb-0">
      {{ 'components.issueTypePermissionsComponent.groups.permissions.viewer' | translate }}
    </label>
  </div>
</div>
<tmt-issue-type-permissions-performers
  [lines]="issueTypeCardService.viewers"
  [readonly]="readonly"
  [entityId]="entityId"
  [gridHeader]="'components.issueTypeCardComponent.groups.viewers' | translate"
/>
<div class="row mt-4">
  <div class="col-12">
    <label class="group mb-0">
      {{ 'components.issueTypePermissionsComponent.groups.permissions.editor' | translate }}
    </label>
  </div>
</div>
<tmt-issue-type-permissions-performers
  [lines]="issueTypeCardService.editors"
  [readonly]="readonly"
  [entityId]="entityId"
  [gridHeader]="'components.issueTypeCardComponent.groups.editors' | translate"
/>
