@if (boardService.config) {
  <div class="board">
    <div class="board__toolbar">
      <wp-filter />
      <tmt-board-sort-button
        [properties]="boardService.sortItems"
        [storageName]="'board.' + boardService.config.id"
        (orderQuery$)="sortChange($event)"
      />
      <div ngbDropdown>
        <button type="button" class="btn btn-default without-caret" ngbDropdownToggle>
          <i class="bi bi-columns"></i>
          {{
            boardService.columnMode() === 'states'
              ? ('components.boardComponent.props.columnsByStates.short' | translate)
              : ('components.boardComponent.props.customColumns.short' | translate)
          }}
        </button>
        <div ngbDropdownMenu>
          <button (click)="boardService.changeColumnMode('states')" ngbDropdownItem>
            {{ 'components.boardComponent.props.columnsByStates.value' | translate }}
          </button>
          <button (click)="boardService.changeColumnMode('custom')" ngbDropdownItem>
            {{ 'components.boardComponent.props.customColumns.value' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="board__content">
      @if (columns.length) {
        <div
          @initAndDestroy
          class="board__columns-header-wrap"
          [tmtDragAndDrop]="columns"
          [tmtDragAndDropOptions]="ddOptions"
          [dragDisabled]="!boardService.config.editAllowed || columns.length === 1"
        >
          @if (boardService.cardsByColumns) {
            @for (column of columns; track column.id) {
              <tmt-board-column-header
                @destroy
                [column]="column"
                [ngClass]="ddOptions.draggableClass"
              />
            }
          }

          @if (boardService.config.editAllowed && !(boardService.loading$ | async)) {
            <div>
              <button
                class="btn btn-sm btn-default board__columns-header-button"
                (click)="openColumnForm($event)"
                [ngbTooltip]="'components.boardColumnHeaderComponent.actions.addColumn' | translate"
                placement="top"
                container="body"
              >
                <i class="bi bi-plus"></i>
              </button>
            </div>
          }
        </div>
      }

      <wp-loading-indicator [loading]="boardService.loading$ | async" />

      @if (!(boardService.loading$ | async)) {
        <div class="board__columns-content">
          @for (column of boardService.activeColumns; track column.id) {
            <tmt-board-track
              @initAndDestroy
              [cards]="boardService.cardsByColumns[column.id]"
              [column]="{
                id: column.id
              }"
            />
          }
        </div>
      }
    </div>
  </div>
} @else {
  <p class="text-center pt-3">
    {{ 'shared.entityNotFound.title' | translate }}
    <br />
    {{ 'shared.entityNotFound.details' | translate }}
  </p>
}
