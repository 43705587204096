import {
  afterNextRender,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  GridComponentColumn,
  GridColumnType,
} from 'src/app/shared/models/inner/grid-column.interface';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  AbstractControl,
} from '@angular/forms';
import _ from 'lodash';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { PerformerCellComponent } from '../performer-cell/performer-cell.component';
import { PerformerReadonlyCellComponent } from '../performer-cell/performer-readonly-cell.component';
import { Guid } from 'src/app/shared/helpers/guid';
import { Performer } from 'src/app/shared/models/entities/state.model';
import { PerformersService } from '../performers.service';

import { Observable, Subject } from 'rxjs';
import { allPerformerTypes } from '../model/allPerformerTypes.const';
import { takeUntil } from 'rxjs/operators';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

/* Таблица исполнителей */
@Component({
  selector: 'wp-performers',
  templateUrl: './performers.component.html',
  styleUrls: ['./performers.component.scss'],
  providers: [GridService],
})
export class PerformersComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() performerLines: UntypedFormArray;
  @Input() lifecycleId: string;
  @Input() performerTypes: string[] = allPerformerTypes;
  @Input() gridHeader = '';
  @Input() excludedRoles: string[] = [];
  @Input() predefinedRoles: NamedEntity[] | Observable<NamedEntity[]>;

  /** Параметры грида. */
  public gridOptions: GridOptions;

  private destroyed$ = new Subject<void>();

  constructor(
    public gridService: GridService,
    private fb: UntypedFormBuilder,
    private service: PerformersService,
  ) {
    afterNextRender(() => {
      this.sortLines();
    });
  }

  ngOnInit(): void {
    this.service.predefinedRoles = this.predefinedRoles;

    this.gridOptions = {
      css: 'wp-nested-table stop-context',
      sorting: false,
      rowCommands: [
        {
          name: 'delete',
          label: 'shared.actions.delete',
          allowedFn: () => !this.readonly,
          handlerFn: (formGroup: UntypedFormGroup, index: number) =>
            this.deletePerformer(index),
        },
      ],
      commands: [
        {
          name: 'create',
          handlerFn: () => null,
        },
      ],
      view: {
        name: 'performerLines',
        columns: [
          {
            name: 'performer',
            header: this.gridHeader,
            hint: this.gridHeader,
            component: PerformerCellComponent,
            readonlyComponent: PerformerReadonlyCellComponent,
            type: GridColumnType.Component,
            property: 'performer',
            width: '100%',
          } as GridComponentColumn,
        ],
      },
    };

    this.service.lifecycleId = this.lifecycleId;
    this.service.performerArray = this.performerLines;
    this.service.performerTypes = this.performerTypes;
    this.service.excludedRoles = this.excludedRoles;
    this.service.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.gridService.detectChanges());
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  /** Добавить исполнителя. */
  public addPerformer(performer?: Performer) {
    this.performerLines.markAsDirty();
    const performerLine = this.fb.group({
      id: Guid.generate(),
      performer: [
        {
          id: '',
          name: null,
          type: this.performerTypes[0],
        } as Performer,
        this.validatePerformer(),
      ],
    });
    if (performer) {
      performerLine.controls.performer.patchValue(performer);
    }
    this.performerLines.push(performerLine);
    this.gridService.detectChanges();
    /** Выделяем строку только если добавляем ее пустой */
    if (!performer) {
      this.gridService.selectGroup(performerLine);
    }
  }

  /** Удалить исполнителя. */
  public deletePerformer(index?: number) {
    const performers = this.performerLines.value as Performer[];
    index =
      index ??
      performers.findIndex(
        (s) => s.id === this.gridService.selectedGroup.value.id,
      );

    this.performerLines.removeAt(index);
    this.performerLines.markAsDirty();
  }

  private validatePerformer() {
    return (control: AbstractControl) => {
      const value = control.value;
      return !value.name && !value.id ? { emptyPerformer: true } : null;
    };
  }

  /** Sorts lines form array. */
  private sortLines(): void {
    this.performerLines.setValue(
      _.orderBy(this.performerLines.value, [
        (item) => this.performerTypes.indexOf(item.performer.type),
        (item) => item.performer.name,
      ]),
    );
  }
}
